<script>
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import Footer from '@/components/Footer/main.vue';
import appConfig from '@/app.config';
import i18n from '@/i18n';
import LoadingIcon from '@/components/Loading/main.vue';
import UploadFile from '@/components/Button/upload-file.vue';
import { checkPermission, campaignMethods, manualService } from '@/state/helpers';
import { showMessage } from '@/utils/messages';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: i18n.t('manual.title'),
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        PageHeader,
        LoadingIcon,
        UploadFile,
        Footer
    },
    data() {
        return {
            title: i18n.t('manual.title'),
            index: 1,
            isLoading: false,
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: i18n.t('manual.title'),
                    active: true
                }
            ],
            initForm: {
                company_name: '',
                main_office: '',
                url: '',
                date_created: '',
                configDateCreated: {
                    error: false,
                    errorDateCreated: ''
                },
                capital: '',
                business_content: '',
                center_office: '',
                chief_executive_officer: '',
                number_of_employees: '',
                organizational_structure: '',
                certification: '',
                technical_safety: '',
                organizational_security_management: '',
                physical_security_management: '',
                human_security_management: '',
                logo: {
                    id: null,
                    path: ''
                }
            },
            fileInfo: {
                originName: '',
                extension: '',
                fileSize: 0
            },
            isUploadFile: false
        };
    },

    mounted() {
        if (!this.checkPermiss('master.register')) {
            this.$router.push({ path: '/' }).then(() => {
                showMessage(8080, this.$bvToast, 'アクセス権限がありません。');
            });
        }
        this.getDetail();
    },
    methods: {
        ...campaignMethods,
        ...manualService,

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        handleFileInput(receivedFile) {
            let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
            let allowedSize = 1024; // 1MB
            if (!receivedFile.files.length) return;
            const file = receivedFile.files[0];
            this.fileInfo.extension = file.name.split('.').pop(); // Lấy phần mở rộng của tệp
            this.fileInfo.type = file.type; // Lấy phần mở rộng của tệp
            this.fileInfo.fileSize = (file.size / 1024).toFixed(2); // Chuyển đổi size sang KB và làm tròn đến 2 chữ số thập phân
            if (allowedExtension.indexOf(this.fileInfo.type) <= -1) {
                showMessage(8080, this.$bvToast, this.$t('uploadFile.format'));
                return;
            }

            if (this.fileInfo.fileSize >= allowedSize) {
                showMessage(8080, this.$bvToast, this.$t('uploadFile.maxSize1MB'));
                return;
            }
            this.fileInfo.originName = file.name;
            this.initForm.logo.origin_name = file.name;
            this.initForm.logo.path = '';
            let query = `origin_name=${this.fileInfo.originName}&extension=${
                this.fileInfo.extension
            }&file_size=${parseInt(this.fileInfo.fileSize)}&type=${this.fileInfo.type}`;
            this.isUploadFile = true;
            this.uploadFile(query).then((data) => {
                if (data.code == 200) {
                    data.data.origin_name = this.fileInfo.originName;
                    data.data.extension = this.fileInfo.extension;
                    data.data.fileSize = this.fileInfo.fileSize;
                    this.initForm.logo.id = data.data.id;
                    receivedFile.value = '';
                    const formData = new FormData();
                    formData.append('file', file);
                    const requestOptions = {
                        method: 'PUT',
                        body: file
                    };
                    fetch(data.data.url, requestOptions)
                        .then((response) => response.text())
                        .then((result) => console.log(result))
                        .catch((error) => console.log('error', error));
                }
                this.isUploadFile = false;
            });
        },

        handleSubmit() {
            let dataObject = Object.assign({}, this.initForm);
            delete dataObject.configDateCreated;
            if (!dataObject.logo.id) {
                dataObject.logo = {
                    id: null
                };
            }

            dataObject.code = 1;
            this.isLoading = true;
            this.updateManual(dataObject)
                .then((res) => {
                    this.isLoading = false;
                    if (res.code !== 200) {
                        showMessage(res.code, this.$bvToast, res.message);
                        return;
                    }
                    showMessage(res.code, this.$bvToast, '登録されました。');
                    this.getDetail();
                })
                .catch((error) => {
                    console.log('error', error);
                    this.fileInfo.originName = '';
                    this.isLoading = false;
                });
        },

        getDetail() {
            this.isLoading = true;
            let code = 1;
            this.detailManual(code)
                .then((res) => {
                    this.initForm = {
                        ...this.initForm,
                        ...res
                    };
                    this.fileInfo.originName = res.logo?.origin_name ?? '';
                    this.initForm.logo = {
                        id: res.logo?.id ?? null,
                        origin_name: res.logo?.origin_name ?? '',
                        path: res.logo?.path ?? ''
                    };
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log('error', error);
                    this.isLoading = false;
                });
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div v-if="!isLoading">
            <h4 class="ml-2">{{ $t('manual.overview') }}</h4>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="handleSubmit" autocomplete="off">
                            <h5 class="mb-5">{{ $t('manual.titleForm1') }}</h5>
                            <div class="form-group row">
                                <label class="col-sm-2" for="nameCompany">{{ $t('manual.nameCompany') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.company_name" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="mainOffice">{{ $t('manual.mainOffice') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.main_office" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="url">{{ $t('manual.url') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.url" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="dateCreated">{{ $t('manual.dateCreated') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.date_created" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="capital">{{ $t('manual.capital') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.capital" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="businessContent">{{ $t('manual.businessContent') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.business_content" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="centerOffice">{{ $t('manual.centerOffice') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.center_office" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="chiefExecutiveOfficer">{{ $t('manual.chiefExecutiveOfficer') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.chief_executive_officer" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="numberOfEmployees">{{ $t('manual.numberOfEmployees') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.number_of_employees" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="organizationalStructure">{{ $t('manual.organizationalStructure') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.organizational_structure" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="certification">{{ $t('manual.certification') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.certification" class="form-control" />
                                </div>
                            </div>
                            <h5 class="my-5">{{ $t('manual.titleForm2') }}</h5>
                            <div class="form-group row">
                                <label class="col-sm-2" for="technicalSafety">{{ $t('manual.technicalSafety') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.technical_safety" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="organizationalSecurityManagement"
                                    >{{ $t('manual.organizationalSecurityManagement') }}
                                </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.organizational_security_management" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="physicalSecurityManagement">{{ $t('manual.physicalSecurityManagement') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.physical_security_management" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="humanSecurityManagement">{{ $t('manual.humanSecurityManagement') }} </label>
                                <div class="col-sm-10">
                                    <textarea rows="5" v-model="initForm.human_security_management" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2" for="to">{{ $t('manual.logo') }} </label>
                                <div class="col-sm-10">
                                    <UploadFile @fileInput="handleFileInput" :title="$t('btn.uploadFile')" />
                                    <div v-if="fileInfo.originName || initForm.logo.origin_name" class="d-flex align-items-center my-2">
                                        {{ fileInfo.originName ? fileInfo.originName : initForm.logo.origin_name ? initForm.logo.origin_name : '' }}
                                        <span
                                            class="ml-2"
                                            style="cursor: pointer; font-size: 20px"
                                            @click="
                                                () => {
                                                    fileInfo.originName = '';
                                                    initForm.logo.origin_name = '';
                                                    initForm.logo.id = null;
                                                    initForm.logo.path = '';
                                                }
                                            "
                                            ><i class="ri-close-line" style="vertical-align: bottom"></i
                                        ></span>
                                    </div>
                                    <b-img
                                        v-if="initForm.logo.path"
                                        thumbnail
                                        fluid
                                        :src="initForm.logo.path"
                                        :alt="initForm.logo.origin_name"
                                        rounded
                                        style="width: 300px; height: 300px; object-fit: cover; object-position: center"
                                    ></b-img>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="text-center flex-fill">
                    <button type="submit" class="btn btn-primary" @click="handleSubmit" :disabled="isUploadFile">
                        {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                    </button>
                </div>
            </Footer>
        </div>

        <div v-else>
            <div class="d-flex justify-content-center">
                <LoadingIcon />
            </div>
        </div>
    </Layout>
</template>
<style></style>
