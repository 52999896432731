<template>
    <div>
        <label for="upload" class="btn" :style="{ cursor: disabled ? 'pointer' : 'not-allowed' }" :disabled="disabled"
            ><span><i class="fa fa-upload" aria-hidden="true"></i></span> {{ $props.title }}</label
        >
        <input v-if="disabled" type="file" id="upload" ref="fileInput" hidden @change="handleFile" />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Upload a file'
        },
        disabled: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        handleFile() {
            const fileInput = this.$refs.fileInput;
            this.$emit('fileInput', fileInput);
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    display: inline-block;
    color: white;
    cursor: pointer;
}
.btn {
    color: #ffff;
    background-color: #409aff;
    border-radius: 5px;
    margin-bottom: 0;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}
</style>
